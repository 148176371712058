

@import "./bootstrap/scss/functions";
@import "./common/variables";
@import "./bootstrap/scss/mixins";
@import "./bootstrap/scss/root";
@import "./bootstrap/scss/reboot";
@import "./bootstrap/scss/type";
@import "./bootstrap/scss/images";
@import "./bootstrap/scss/code";
@import "./bootstrap/scss/grid";
@import "./bootstrap/scss/tables";
@import "./bootstrap/scss/forms";
@import "./bootstrap/scss/buttons";
@import "./bootstrap/scss/transitions";
@import "./bootstrap/scss/dropdown";
@import "./bootstrap/scss/button-group";
@import "./bootstrap/scss/input-group";
@import "./bootstrap/scss/custom-forms";
@import "./bootstrap/scss/nav";
@import "./bootstrap/scss/navbar";
@import "./bootstrap/scss/card";
@import "./bootstrap/scss/breadcrumb";
@import "./bootstrap/scss/pagination";
@import "./bootstrap/scss/badge";
@import "./bootstrap/scss/jumbotron";
@import "./bootstrap/scss/alert";
@import "./bootstrap/scss/progress";
@import "./bootstrap/scss/media";
@import "./bootstrap/scss/list-group";
@import "./bootstrap/scss/close";
@import "./bootstrap/scss/modal";
@import "./bootstrap/scss/tooltip";
@import "./bootstrap/scss/popover";
@import "./bootstrap/scss/carousel";
@import "./bootstrap/scss/utilities";
@import "./bootstrap/scss/print";

@at-root {
  @-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;

}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "./bootstrap/scss/mixins/breakpoints";
@import "./bootstrap/scss/mixins/grid-framework";
@import "./bootstrap/scss/mixins/grid";

@import "./bootstrap/scss/grid";
@import "./bootstrap/scss/utilities/display";
@import "./bootstrap/scss/utilities/flex";
